import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiArrowCircleRight } from "react-icons/pi";
import { IoIosGitMerge } from "react-icons/io";
import { MdOutlineHistory } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Seamless Integration for SaaS, M&A Projects and More",
      icon: <IoIosGitMerge />, 
      // description: "Efficiently connect disparate systems during business mergers or acquisitions to ensure smooth operations from day one.",
    },
    {
      heading: "Upgrade and Modernize Legacy Systems",
      icon: <MdOutlineHistory />, 
      // description: "Transform outdated systems into modern, efficient platforms that align with current business demands.",
    },
    {
      heading: "Future-Proof Your Tech Stack",
      icon: <PiArrowCircleRight />, 
      // description: "Create flexible platforms designed to easily integrate new tools and technologies as your business evolves.",
    },
    {
      heading: "Streamlined Data Flow and Automation",
      icon: <FiArrowRight />, 
      // description: "Establish automated workflows and seamless data transfers across integrated systems to reduce inefficiencies.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>System Integration Services | LariatCo</title>
  <meta
    name="description"
    content="Streamline your operations with LariatCo's system integration services. Modernize legacy systems, automate workflows, and create a flexible, future-proof tech stack for seamless connectivity."
  />
  <meta
    name="keywords"
    content="system integration, legacy system modernization, SaaS integration, mergers and acquisitions, data automation, tech stack, business connectivity"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="System Integration Services | LariatCo" />
  <meta
    property="og:description"
    content="Simplify complexity with LariatCo's system integration services. Seamlessly connect legacy systems, automate workflows, and future-proof your tech stack."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/system-integration" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="System Integration Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Streamline your business with LariatCo's expert system integration. Modernize legacy systems, automate workflows, and create a future-ready tech stack."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>System Integration</Subheading>}
  heading={
    <>
      <HighlightedText>Seamless Connectivity for <span style={{ color: "#0000D3"}}> RESULTS</span></HighlightedText>
    </>
  }
  description="Simplify complexity with expert system integration services. From linking legacy systems to building platforms ready to adopt future tech stacks, our solutions ensure seamless transitions and long-term adaptability for your business operations."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
