import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import blogImage1 from "../../images/stock/blogs/blog1.jpg"
import blogImage2 from "../../images/stock/blogs/blog2.jpg"
import blogImage3 from "../../images/stock/blogs/blog3.jpg"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-xl text-marketing-secslate text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-marketing-pricobolt flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-marketing-pricobolt text-lg`;
const Description = tw.p`mt-2 text-sm text-marketing-secslate`;
const Category = tw.p`mt-2 text-sm text-marketing-pricobolt`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm bg-marketing-secslate text-white hover:bg-marketing-pricobolt font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = "",
  description = "Some of our more recent blogs",

}) => {
  const blogPosts = [
    {
      imageSrc:
        blogImage1,
    //   author: "Adam Wathan",
      category: "Data Infrastructure",
      title: "Building Data Infrastructure for Small Businesses on a Budget",
      description: "Practical tips and tools to help businesses like yours leverage affordable solutions for better decision-making, increased efficiency, and long-term growth.",
      url: "/blog/id:100"
    },
    {
      imageSrc:
      blogImage2,
    //   author: "Owais Khan",
      category: "Business Intelligence",
      title: "The Intersection of Data Analytics and Business Intelligence",
      description: "Explore how businesses can transform overwhelming datasets into actionable strategies that enhance customer experiences, streamline operations, and boost profitability.",
      url: "/blog/id:105"
    },
    {
      imageSrc:
      blogImage3,
    //   author: "Steve Schoger",
      category: "Business Assessment",
      title: "AI 101: Empowering Your Business for the Future",
      description: "Discover what AI is, how it works, and why understanding its fundamentals is essential for every employee, leader, and manager in today's rapidly evolving business landscape.",
      url: "/blog/id:107"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <TagIcon />
                      <Category>{post.category}</Category>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description >{post.description}</Description>
                  <Link href={post.url}>Read Post</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
