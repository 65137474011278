import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiDesktopTower, PiWrench, PiCode, PiMonitor } from "react-icons/pi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Custom Application Design for Every Need",
      icon: <PiCode />, 
      // description: "Create tailored applications designed to fit your business's unique processes, whether for mobile, web, or enterprise platforms, ensuring seamless functionality and scalability.",
    },
    {
      heading: "Streamlined Tech Stacks",
      icon: <PiWrench />, 
      // description: "Optimize your business tech stack to boost efficiency and reduce complexity.",
    },
    {
      heading: "Comprehensive Workspace Solutions",
      icon: <PiMonitor/>, 
      // description: "Design and implement employee workspaces with cloud-first solutions, optimized hardware, and seamless collaboration tools to enhance productivity across your organization.",
    },
    {
      heading: "Automated Workflows and Managed Support",
      icon: <PiDesktopTower />, 
      // description: "Enhance productivity with automated workflows and fully managed employee workspaces.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Technology Architecture Services | LariatCo</title>
  <meta
    name="description"
    content="Transform your business with LariatCo's technology architecture services. Custom applications, streamlined tech stacks, and fully managed workspaces designed for growth and efficiency."
  />
  <meta
    name="keywords"
    content="technology architecture, custom applications, tech stack optimization, cloud solutions, hardware integration, managed workspaces, automated workflows"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Technology Architecture Services | LariatCo" />
  <meta
    property="og:description"
    content="Empower your business with LariatCo's technology architecture services. Tailored solutions for optimized operations, scalability, and growth."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/technology-architecture" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Technology Architecture Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Optimize your technology architecture with LariatCo. Custom-built applications, cloud solutions, and managed workspaces to drive efficiency and growth."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Technology Architecture</Subheading>}
  heading={
    <>
    <HighlightedText>Custom Solutions <span style={{ color: "#0000D3"}}> BUILT FOR GROWTH</span></HighlightedText>
    </>
  }
  description="Transform your business with custom-built technology solutions. 
  From tailored software and optimized tech stacks to cloud or hardware integration and fully managed employee workspaces, 
  our end-to-end approach ensures efficiency, scalability, and ongoing support."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
