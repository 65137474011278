// ** this is the original ThreeColSimple **//
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import { PiCheck } from "react-icons/pi";
import { PiBookOpenTextThin } from "react-icons/pi";
import { PiTarget } from "react-icons/pi";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { PiShieldCheckered } from "react-icons/pi";

const Heading = tw(SectionHeading)`text-marketing-secslate`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto text-marketing-secslate`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl text-marketing-secslate leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-marketing-secslate`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;


export default ({
  cards = [
    {
      imageSrc: <PiCheck />,
      icon: <PiCheck />,
      title: "Simple",
      description: "We care about simple solutions that steamlines business growth and efficiency", 
      url: "https://timerse.com"
    },
    {
      imageSrc: <PiBookOpenTextThin />,
      icon: <PiBookOpenTextThin />,
      title: "Authentic",
      description: "We strive to provide the 'real deal' that is focused on the genuine care and attention you deserve",
      url: "https://google.com"
    },
    {
      imageSrc: <PiTarget />,
      icon: <PiTarget />,
      title: "Deliver",
      description: "We are driven to not only deliver results, but a return on investment that is meausurable and actionable",
      url: "https://reddit.com"
    },
    {
      imageSrc: <PiLightbulbFilamentLight />,
      icon: <PiLightbulbFilamentLight />,
      title: "Innovation",
      description: "We are focued on staying on top of the ever changing landscape of business, data, and technology",
      url: "https://reddit.com"
    },
    {
      imageSrc: <PiShieldCheckered />,
      icon: <PiShieldCheckered/>,
      title: "Protect",
      description: "We value your trust in us to be a partner that protects what makes your business succeed",
      url: "https://reddit.com"
    }
  ],
  linkText = "",
  heading = "What matters",
  subheading = "",
  description = "Our aim is to be a long-term partner and to achieve that, we know our values must prevail in all that we do.",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card >
              <span className="icon" style={{ fontSize: "2rem", color: "#243E63"  }}>
              {card.icon} {/* Render the icon directly */}
            </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
