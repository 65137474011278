import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../components/hero/HomePageHero";
import Features from "../components/features/HomeFeatures";
import MainFeature1 from "components/features/HomeFeature1o1";
import MainFeature2 from "../components/features/HomeFeature2.js";
import FeatureWithSteps from "../components/features/HomeFeatureWithSteps.js";
import Committment from "../components/features/HomeCommittment"
import GetStarted from "../components/cta/HomeGetStarted";
import SimpleContactUs from "../components/forms/HomeContactUs";
import Footer from "../components/footers/MainFooter";
import macHeroScreenshotImageSrc from "../images/stock/process3.jpg";
import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
import MainHeader from "components/headers/MainHeader";


export default () => {
  const Subheading = tw.span`text-center uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-pricobolt`;

  return (
    <AnimationRevealPage>
      <MainHeader />
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading style={{ color: "#0000D3" }}>Services</Subheading>}
        heading={
          <>
            What we <HighlightedText style={{ color: "#0000D3" }}>DO</HighlightedText>
          </>
        }
      />

      <MainFeature1 
        subheading={<Subheading style={{ color: "#0000D3" }}>CHALLENGES</Subheading>} 
      />

        <MainFeature2
        subheading={<Subheading style={{ color: "#0000D3" }}>WHY US</Subheading>}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
        
      />

      <FeatureWithSteps
        subheading={<Subheading style={{ color: "#0000D3" }}>OUR PROCESS</Subheading>}
        // I am leaving this here, commented out to show how you can use the component but make a custom adjustment based on the need.
        // heading={
        //   <>
        //     Easy to <HighlightedText>Get Started.</HighlightedText>
        //   </>
        // }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      
      
      <Committment />
      
      <GetStarted/>
      <SimpleContactUs/>
      <Footer />
    </AnimationRevealPage>
  );
}
