import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { FaClipboardList, FaLightbulb, FaRegChartBar, FaUserTie } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Access Expert Fractional CIOs",
      icon: <FaUserTie />,
      // description: "Bring in experienced technology leaders to guide data-driven decision-making and infrastructure growth.",
    },
    {
      heading: "Flexible Support for Technology Initiatives",
      icon: <FaLightbulb />,
      // description: "Engage fractional leaders for projects, transitions, or strategic growth tailored to your needs.",
    },
    {
      heading: "Bridge Data Leadership Gaps",
      icon: <FaClipboardList />,
      // description: "Fill critical leadership roles quickly during organizational changes or resource shortages.",
    },
    {
      heading: "Optimize and Scale Data Systems",
      icon: <FaRegChartBar />,
      // description: "Enhance operational efficiency and scalability with strategic technology planning and oversight.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Fractional Leadership Services | LariatCo</title>
  <meta
    name="description"
    content="Leverage LariatCo's seasoned fractional leadership to fill CIO-level expertise gaps. Guide your data strategy, scale infrastructure, and optimize operations with flexible technology leadership."
  />
  <meta
    name="keywords"
    content="fractional leadership, fractional CIO, data strategy, technology leadership, infrastructure optimization, scalable solutions, strategic planning"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Fractional Leadership Services | LariatCo" />
  <meta
    property="og:description"
    content="Access expert fractional CIOs with LariatCo to drive data strategy, technology planning, and scalable growth. Flexible support for your business needs."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/fractional-leadership" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Fractional Leadership Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Bridge leadership gaps with LariatCo's fractional CIO services. Drive data strategy, optimize infrastructure, and scale technology initiatives."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/images/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Fractional Leadership</Subheading>}
  heading={
    <>
      <HighlightedText>Data Technology Expertise <span style={{ color: "#0000D3"}}> ON DEMAND</span></HighlightedText>
    </>
  }
  description="Leverage seasoned fractional leadership to guide your data strategy and technology initiatives. Our experts bridge gaps in CIO-level expertise, helping your business scale, optimize infrastructure, and ensure robust data governance—exactly when and where you need it."
  icons={page1Icons}
  />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
