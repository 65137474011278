// This is edited to customize
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { BsClipboard2Check, BsGear, BsFloppy, BsArrowsExpand, BsBarChart, BsLightningCharge} from "react-icons/bs";


const Container = tw.div`relative bg-marketing-priaqua -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-xl text-marketing-secslate`;
const Heading = tw(SectionHeading)`w-full text-marketing-secslate`;
const Description = tw(SectionDescription)`w-full text-center text-marketing-secslate`;

const VerticalSpacer = tw.div`mt-10 w-full`;
const Button = tw.div`px-8 py-3 mt-8 mb-3 font-bold rounded bg-marketing-secslate text-gray-100 hocus:bg-marketing-pricobolt hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`} 
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider text-marketing-secslate font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-marketing-secslate leading-snug`}
  }
    .icon {
      ${tw`ml-2 w-4 text-marketing-secslate`}
    }
`;


export default ({cards = undefined, heading = <>How we <span tw="text-marketing-pricobolt">HELP</span></>, subheading = "", description = "These are some of the top challenges our clients face.  Allow us to come along side you in your journey."
}) => {
  
  const defaultCards = [
    {
      imageSrc: <BsArrowsExpand />,
      icon: <BsArrowsExpand />,
      title: "System Connectivity",
      description: "Disconnected SaaS programs and poor data integration lead to redundant systems, fragmented data views, and workflow issues, wasting time and increasing errors."
    },
    {
      imageSrc: <BsGear />,
      icon: <BsGear />,
      title: "Actionable Insights",
      description: "Delays, inefficiencies, and lack of data-driven insights make informed decision-making difficult and hamper productivity."
    },
    {
      imageSrc: <BsClipboard2Check />,
      icon: <BsClipboard2Check />,
      title: "Compliance & Security",
      description: "Meeting complex regulatory standards while protecting against security risks creates significant challenges and consumes valuable resources."
    },
    {
      imageSrc: <BsFloppy />,
      icon: <BsFloppy />,
      title: "Modernization ",
      description: "Balancing the value of legacy systems with the need for modernization creates uncertainty, operational risks, and limits scalability."
    },
    {
      imageSrc: <BsLightningCharge />,
      icon: <BsLightningCharge />,
      title: "Automation Gaps",
      description: "Missed opportunities to streamline processes through automation lead to repetitive tasks, increased errors, and wasted resources."
    },
    {
      imageSrc: <BsBarChart />,
      icon: <BsBarChart />,
      title: "Scaling Operations",
      description: "Inefficient workflows and outdated systems make it difficult to scale operations smoothly, leading to growing pains and resource constraints."
    }
];




  
  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading >{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
            <span className="icon" style={{ fontSize: "1.5rem" }}>
              {card.icon} {/* Render the icon directly */}
            </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <Button  as="a" href={`/services`}>
              Learn More
            </Button>
      </ThreeColumnContainer>
    </Container>
  );
};
