import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import AboutUsFeature from "components/features/AboutUsFeature"
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/AboutUsValues";
import AboutUsOurWhy from "components/features/AboutUsOurWhy";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import GetStarted from "../components/cta/HomeGetStarted";
import SimpleContactUs from "../components/forms/HomeContactUs";
import visionImg from "../images/stock/aboutVision6.jpg"
import missionImg from "../images/stock/aboutMission6.jpg"
import { Helmet } from "react-helmet";

const Subheading = tw.span`uppercase tracking-wider text-sm text-marketing-pricobolt`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>About Us | Simplifying Technology for Growth | LariatCo</title>
        <meta
          name="description"
          content="Simplify your operations, maximize business potential, and drive growth with LariatCo's efficient and secure technology solutions."
        />
        <meta
          name="keywords"
          content="business growth, technology solutions, business solutions, solution, efficiency, operational efficiency, security, business transformation"
        />
        <meta name="author" content="LariatCo" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="About Us | Simplifying Technology for Growth" />
        <meta
          property="og:description"
          content="Our vision is to empower businesses to thrive by simplifying complex systems. Learn about our mission, vision, and values."
        />
        <meta property="og:image" content="https://www.lariatco.com/banner.png" />
        <meta property="og:url" content="https://www.lariatco.com/aboutus" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Simplifying Technology for Growth" />
        <meta
          name="twitter:description"
          content="Learn how we simplify complex systems and help businesses achieve growth, security, and operational efficiency."
        />
        <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
      </Helmet>
      <Header />
      <AboutUsFeature />
      <MainFeature1
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Vision</Subheading>}
        heading="We make the complex simple & manageable."
        description="Many businesses face frustrations with inefficiencies, complex systems, and fragmented operations, leading to reduced value, 
        missed opportunities, and increased stress and security risks. Our vision is to help businesses transform operations and achieve efficiency, 
        security, and growth through simple, innovative, and reliable resources."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc={visionImg}
      />
      <MainFeature1
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Mission</Subheading>}
        heading="Maximize your business potential."
        description="Our mission is to empower businesses to thrive by making complex technology solutions accessible and manageable.  
        We offer tailored solutions that provide strategic and streamlined processes, integrated systems, and a transformative business, helping 
        you be confident as you grow and innovate."
        buttonRounded={false}
        primaryButtonText="Lets Chat"
        primaryButtonUrl="/scheduling"
        imageSrc={missionImg}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Values</Subheading>}
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <AboutUsOurWhy />
      <GetStarted />
      <SimpleContactUs />
      
      <Footer />
    </AnimationRevealPage>
  );
};
