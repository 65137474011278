import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/MainHeader.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

const PageContainer = tw.div`flex flex-col items-center justify-center min-h-screen bg-gray-100 p-8`;
const ContentContainer = tw.div`flex flex-row justify-between w-full max-w-6xl`;
const InfoContainer = tw.div`flex flex-col justify-between w-1/2 p-6 bg-white shadow-lg rounded-lg m-2`;
const ContactContainer = tw.div`w-1/2 p-6 bg-gray-200 shadow-lg rounded-lg m-2`;
const Title = tw.h2`text-xl font-bold text-gray-900 mb-4`;
const Description = tw.p`text-gray-700 mb-4`;
const Link = styled.a`
  ${tw`text-blue-500 underline hover:text-blue-700`}
`;
const Button = tw.button`px-6 py-2 mt-4 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 transition duration-300`;

const PreScheduling = () => {
  return (
    <>
    <AnimationRevealPage>
    <Header>
    <PageContainer>
      <ContentContainer>
        <InfoContainer>
          <Title>Booking Option 1</Title>
          <Description>This is the description for the first booking option.</Description>
          <Link href="/scheduling">Go to Booking Page 1</Link>
          <Button onClick={() => (window.location.href = "/scheduling")}>
            Book Now
          </Button>
        </InfoContainer>

        <InfoContainer>
          <Title>Booking Option 2</Title>
          <Description>This is the description for the second booking option.</Description>
          <Link href="/scheduling">Go to Booking Page 2</Link>
          <Button onClick={() => (window.location.href = "/scheduling")}>
            Book Now
          </Button>
        </InfoContainer>
      </ContentContainer>

      <ContactContainer>
        <Title>Contact Information</Title>
        <Description>
          For inquiries, please contact us at:
          <br />
          Phone: (123) 456-7890
          <br />
          Email: info@bookingexample.com
        </Description>
      </ContactContainer>
    </PageContainer>
    </Header>
    </AnimationRevealPage>
    </>
  );
};

export default PreScheduling;