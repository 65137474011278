import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {ThemeProvider, BaseStyles} from '@primer/react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

import HomePage from "pages/HomePage";
/* Inner Pages */
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import Services from "pages/Services/Services.js";

import BusinessAssessment from "pages/Services/BusinessAssessment";
import TechnologyArchitecture from "pages/Services/TechnologyArchitecture";
import FractionalLeadership from "pages/Services/FractionalLeadership";
import DataTechnology from "pages/Services/DataInfrastructure";
import Integraton from "pages/Services/Integration";
import CyberSecurity from "pages/Services/CyberSecurity";
import BusinessIntelligence from "pages/Services/BusinesIntelligence";
import TermsOfService from "pages/TermsOfService";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Scheduling from "pages/Scheduling";
import PreScheduling from "pages/preScheduling";
import BlogPage from "pages/Blog/BlogPage";
import Blog1 from "pages/Blog/Blog1";
import Blog2 from "pages/Blog/Blog2";
import Blog3 from "pages/Blog/Blog3";
// import Ai from "pages/Ai/Ai"
// import BlogPage from "pages/Blog/BlogPage"
// import HIT from "pages/H.I.T.Page"


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
    <ThemeProvider>
      <BaseStyles>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/business-assessment" element={<BusinessAssessment />} />
          <Route path="/system-integration" element={<Integraton />} />
          <Route path="/fractional-leadership" element={<FractionalLeadership />} />
          <Route path="/data-infrastructure" element={<DataTechnology />} />
          <Route path="/business-intelligence" element={<BusinessIntelligence />} />
          <Route path="/technology-architecture" element={<TechnologyArchitecture />} />
          <Route path="/cyber-security" element={<CyberSecurity />} />          
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/terms-and-conditions" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/scheduling-options" element={<PreScheduling />} />
          <Route path="/scheduling" element={<Scheduling />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/id:100" element={<Blog1 />} />
          <Route path="/blog/id:105" element={<Blog2 />} />
          <Route path="/blog/id:107" element={<Blog3 />} />
        </Routes>
      </Router>
      <Analytics />
      <SpeedInsights />
      </BaseStyles>
      </ThemeProvider>
    </>
  );
}


