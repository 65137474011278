// ** I have made edits to customize. ** //
import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Container as ContainerBase } from "components/misc/Layouts.js"

const Container = tw(ContainerBase)`-mx-8`;
const Content = tw.div`max-w-full mx-auto`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-marketing-secslate text-gray-100 relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-marketing-priaqua focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between `;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-marketing-secseafoam text-gray-100 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-marketing-pricobolt hocus:-translate-y-px hocus:shadow-xl`;

export default () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Send form data to the server
      const response = await fetch('/api/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the form data
      });
  
      // Handle the server response
      if (response.ok) {
        const result = await response.json();
        alert("Message sent successfully!");
        console.log("Server Response:", result);
        // Reset the form after successful submission
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        const error = await response.json();
        console.error("Server Error:", error);
        alert(error.error || "Failed to send the message. Please try again.");
      }
    } catch (error) {
      console.error("Network Error:", error);
      alert("A network error occurred. Please check your connection and try again.");
    }
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl border-2 px-8 py-8 border-marketing-priaqua shadow-lg">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <TwoColumn>
                <Column>
                <InputContainer>
                    <Label htmlFor="name-input">Your Full Name</Label>
                    <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe" value={formData.name} onChange={handleChange} />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" value={formData.email} onChange={handleChange}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="phone-input">Phone Number</Label>
                    <Input id="phone-input" type="phone" name="phone" placeholder="E.g. 555-555-5555" value={formData.phone} onChange={handleChange} />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="message-input">Your Message</Label>
                    <TextArea id="message-input" name="message" placeholder="E.g. Tell us how we can assist you today" value={formData.message} onChange={handleChange}/>
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};
