import MainHeader from 'components/headers/MainHeader'
import React from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

const Scheduling = () => {
  return (
    <>
    <AnimationRevealPage>
    <MainHeader />
    <div style={{ width: '100vw', height: '100vh', margin: 0, paddingTop: 24 }}>
      <iframe
        src="https://outlook.office365.com/owa/calendar/LariatCo2@lariatco.com/bookings/"
        width="100%"
        height="100%"
        scrolling="yes"
        style={{ border: 0, }}
        title="Booking Information"
      ></iframe>
    </div>
    </AnimationRevealPage>
    </>
  )
}

export default Scheduling