import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Header from "../../components/headers/MainHeader";
import Blog from "components/blogs/BlogListing";
import Footer from "../../components/footers/MainFooter";

export default () => {

  return (
    <>
    <AnimationRevealPage>
    <Header />
    <Blog />
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
