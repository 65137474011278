import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiCertificate, PiTrendUp, PiLockKeyOpen, PiShieldCheck } from "react-icons/pi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Align with Regulations and Security Standards",
      icon: <PiCertificate />, 
      // description: "Ensure your business meets industry regulations with secure, audit-ready systems.",
    },
    {
      heading: "Scalable Security Architecture",
      icon: <PiTrendUp />,
      // description: "Implement security solutions that grow with your business and adapt to new challenges.",
    },
    {
      heading: "Proactively Mitigate Vulnerabilities",
      icon: <PiShieldCheck />, 
      // description: "Identify and address vulnerabilities before they impact your business operations.",
    },
    {
      heading: "Confidence Through Comprehensive Protection",
      icon: <PiLockKeyOpen />, 
      // description: "Gain peace of mind knowing your business is secured by end-to-end cybersecurity solutions.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Cybersecurity Services | LariatCo</title>
  <meta
    name="description"
    content="Protect your business with LariatCo's comprehensive cybersecurity solutions. Ensure compliance, mitigate vulnerabilities, and gain confidence with scalable, proactive security measures."
  />
  <meta
    name="keywords"
    content="cybersecurity, business protection, vulnerability management, regulatory compliance, scalable security, end-to-end protection"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Cybersecurity Services | LariatCo" />
  <meta
    property="og:description"
    content="Safeguard your organization with LariatCo's proactive cybersecurity solutions. Scalable architectures, compliance, and vulnerability protection tailored to your needs."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/cyber-security" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Cybersecurity Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Protect your business with proactive cybersecurity solutions by LariatCo. Mitigate vulnerabilities and ensure compliance with scalable security measures."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Cybersecurity</Subheading>}
  heading={
    <>
      <HighlightedText>Proactive Protection for<span style={{ color: "#0000D3"}}> PEACE OF MIND</span></HighlightedText>
    </>
  }
  description="Empower your organization with proactive cybersecurity solutions that safeguard against evolving threats. From scalable security architectures to vulnerability management and regulatory compliance, we provide end-to-end protection tailored to your business needs."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
