import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";


const PrimaryBackgroundContainer = tw(Container)`-mx-8 mt-8 bg-marketing-priaqua`;
const HeadingContainer = tw.div``
// const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-2`;
// const Heading = tw(SectionHeading)`text-center`;
const Paragraph = tw.p` text-marketing-secslate text-left leading-relaxed max-w-5xl mx-auto px-8`;
const Span = styled.span`
  ${tw`font-bold `} 
`;

export default ({
  subheading = "",
  heading = "",
  description = "",
  
}) => {
  
    return (
        <PrimaryBackgroundContainer>
          <ContentWithPaddingXl>
            <HeadingContainer>
              {/* <Subheading>{subheading}</Subheading> */}
              {/* <Heading>{heading}</Heading> */}
              
    
              {/* Centered paragraphs below the description */}
              <Paragraph>
              With over <Span>50 years</Span> of combined experience in <Span>business ownership</Span>, <Span> strategy</Span>, and
              <Span> leadership</Span>, we bring deep insights into the challenges and opportunities that businesses face. Our approach is to combine 
              business experience with cutting-edge technology to deliver results.  We work alongside leadership teams to address complex problems, 
              drive growth, and implement <Span>adaptable strategies</Span> to build a <Span>strong foundation</Span> for the future. 
              Your leadership, vision, and expertise have positioned your organization for success—now we're here to help you take it to the next level.
          </Paragraph>

          <Paragraph>
            Our approach begins with a comprehensive assessment that highlights areas where <Span>technology</Span>, 
            <Span> operational efficiency</Span>, and <Span>process optimization</Span> can elevate your business. Whether it's ensuring <Span>compliance</Span>, 
            enhancing <Span>productivity</Span>, reinforcing <Span>cybersecurity</Span>, or increasing <Span>visibility</Span>, we create a roadmap tailored for your goals. 
            This roadmap isn't just about technology; it's about making sure every part of your business is equipped to <Span>manage business effectively</Span>, 
            <Span> sustain growth</Span>, and <Span>navigate future risks</Span>.
          </Paragraph>

          <Paragraph>
            With our guidance, you won't need to worry about technical jargon. Our job is to make these solutions <Span>understandable </Span> 
            and <Span> practical</Span>, helping you <Span>integrate systems</Span>, unlock <Span>valuable insights</Span>, and focus on what you do best. 
            We keep it simple: ensuring your business runs <Span>securely</Span> and <Span>efficiently</Span>, giving you the confidence to grow 
            with <Span>purpose</Span> and <Span>resilience</Span>.
          </Paragraph>
            </HeadingContainer>
            </ContentWithPaddingXl>
        </PrimaryBackgroundContainer>
  );
};
