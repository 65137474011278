import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-20 lg:pt-5 pb-20`;

const FormContainer = styled.div`
  ${tw`p-8 sm:p-10 md:p-12 bg-marketing-secseafoam text-marketing-secslate rounded-lg relative`}
  form {
    ${tw`mt-8`}
  }
  h2 {
    ${tw`text-3xl text-center sm:text-4xl font-bold`}
  }
  h3 {
    ${tw`text-2xl text-center sm:text-2xl font-bold mb-4 `}
  }
  h4 {
    ${tw`text-xl mt-2`}
  }
    p {
    ${tw`mt-6 px-8 text-lg`}
  }
`;

const CategoryHeading = styled.h4`
  ${tw`text-lg font-bold mt-6 mb-4 inline-block`}
  border-bottom: 2px solid #243E63;
`;

const QuestionContainer = tw.div`mb-6`;
const CheckboxLabel = tw.label`block mb-4`;
const CheckboxInput = tw.input`mr-2 size-4`;
const InputContainer = tw.div`relative py-2 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide text-marketing-secslate font-semibold text-lg`;
const Input = tw.input`w-full bg-transparent text-white text-base text-sm font-medium tracking-wide border-b-2 pt-6 pb-2 hocus:border-marketing-secslate focus:outline-none transition duration-200`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between mt-6`;
const Column = tw.div`sm:w-6/12 sm:px-6 sm:my-2 flex flex-col mr-6`;
const Divider = tw.div`border-l border-marketing-secslate mx-4`;
const TranCont = tw.div`flex justify-between `
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hover:text-white hocus:-translate-y-px hocus:shadow-xl`;
const NextButton = tw.button`w-full sm:w-32 mt-6 py-3 mr-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hocus:-translate-y-px hocus:shadow-xl`;
const GoBackButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hocus:text-white hocus:-translate-y-px hocus:shadow-xl`;
const LinkButton = tw.button`mt-6 text-marketing-pricobolt hover:underline focus:outline-none`;
const ResultItem = tw.a`block mt-4 text-center text-lg text-marketing-pricobolt hover:underline`;
const ButtonContainer = tw.div`flex flex-col items-center space-y-4 mt-4`;
const OptionButton = tw(PrimaryButtonBase)`w-64 py-3 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hocus:-translate-y-px hocus:shadow-xl bg-marketing-secslate text-gray-100 hocus:bg-white hocus:text-marketing-pricobolt w-64 py-3 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hocus:-translate-y-px hocus:shadow-xl`;
const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

const priorityMapping = {
  STRATEGY: [    
    { question: "We want to implement Ai but are not sure where to start", solution: "Fractional Leadership", priority: 1 },
    { question: "We want a clear, actionable plan with priorities and budget for modernization efforts", solution: "Business Assessment", priority: 1 },        
    { question: "We want to bridge a leadership gap to ensure our tech initiatives", solution: "Fractional Leadership", priority: 1 },
    { question: "We feel ok about our tech stack but need an audit", solution: "Business Assessment", priority: 1 },
    { question: "We are not sure, but we sense a tech shift for our industry ", solution: "Business Assessment", priority: 1 },
  ],
  RESOURCE: [
    { question: "We want skilled dev talent for projects but without full-time hires", solution: "Fractional Leadership", priority: 3 },
    { question: "We want to transform mounds of data into actional resources", solution: "Business Intelligence", priority: 2 },
    { question: "We want to deliver more efficient results for our customers", solution: "Business Intelligence", priority: 2 },
    { question: "We want to start collecting data for our business ", solution: "Data Infrastructure", priority: 2 },
  ],
  SECURE: [
    { question: "We want to prioritize security within our operational platform ", solution: "Cyber Security", priority: 2 },
    { question: "We want to ensure we are compliant with specific regulations ", solution: "Cyber Security", priority: 2 },
  ],
  STRUCTURE: [
    { question: "We want to build a custom application ", solution: "Technology Architecture", priority: 3 },
    { question: "We want to link our SaaS programs for seamless operations", solution: "System Integration", priority: 3 },
    { question: "We want to modernize our legacy systems to align for the future", solution: "System Integration", priority: 3 },
    { question: "We want to wrangle our data into one source of truth", solution: "Data Infrastructure", priority: 3 },
    { question: "We want to level up our tech stack for future growth", solution: "Technology Architecture", priority: 3 },
  ],   
};

const calculateResults = (formData) => {
  const solutions = {
    "Business Assessment": 0,
    "Fractional Leadership": 0,
    "System Integration": 0,
    "Business Intelligence": 0,
    "Technology Architecture": 0,
    "Data Infrastructure": 0,
    "Cyber Security": 0,
  };

  formData.selectedOptions.forEach(answer => {
    const [category, question] = answer.split(": ");
    const mapping = priorityMapping[category]?.find(item => item.question === question);
    if (mapping) {
      solutions[mapping.solution] += mapping.priority;
    }
  });

  const sortedSolutions = Object.entries(solutions)
    .sort(([, priorityA], [, priorityB]) => priorityB - priorityA)
    .map(([solution]) => solution);

  return sortedSolutions;
};

const QuestionnaireForm = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    selectedOptions: [],
    name: '',
    email: '',
    phone: ''
  });
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      selectedOptions: checked
        ? [...prev.selectedOptions, value]
        : prev.selectedOptions.filter(item => item !== value)
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const handleExploreClick = () => navigate("/business-assessment");

  const handleGoBack = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Indicate the start of a process
  
    // Step 1: Calculate results and set them
    const sortedResults = calculateResults(formData);
    setResults(sortedResults);
  
    // Step 2: Prepare the payload
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      selectedOptions: formData.selectedOptions,
    };
  
    // Step 3: Send data to the server
    try {
      const response = await fetch("/api/sendMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      // Step 4: Handle the response
      if (response.ok) {
        const result = await response.json();
        console.log("Email sent successfully:", result);
        alert("Your selections have been sent successfully!");
        setStep(4); // Move to the results step after successful submission
      } else {
        const error = await response.json();
        console.error("Failed to send email:", error.error);
        alert(error.error || "Failed to send your selections. Please try again.");
      }
    } catch (err) {
      console.error("Error submitting form:", err);
      alert("A network error occurred. Please check your connection and try again.");
    } finally {
      setLoading(false); // Ensure loading state is cleared
    }
  };


  const skipToResults = () => {
    setResults(calculateResults(formData));
    setStep(4);
  };

  // Identify selected solutions based on selected options
  const selectedSolutions = results.filter(result => 
    formData.selectedOptions.some(option => {
      const [category] = option.split(": ");
      return priorityMapping[category]?.some(item => item.solution === result);
    })
  );

  // Identify unselected solutions
  const allSolutions = ["Business Assessment", "Fractional Leadership", "System Integration", "Business Intelligence" ,"Technology Architecture", "Data Infrastructure", "Cyber Security"];
  const unselectedSolutions = allSolutions.filter(solution => !selectedSolutions.includes(solution));

  const leftCategories = ["STRATEGY", "SECURE"];
const rightCategories = ["STRUCTURE", "RESOURCE"];

  return (
    <Container>
      <Content>
        <FormContainer>
        <div tw="mx-1 max-w-4xl">
            <h2>Business Specific Solutions</h2>
            <form onSubmit={handleSubmit}>
            {step === 1 && (
                <ButtonContainer>
                  <h3>Need help finding a potential solution?</h3>
                  <OptionButton onClick={handleNextStep}>
                    Yes, guide me!
                  </OptionButton>
                  <OptionButton onClick={handleExploreClick}>
                    No, I'm Just Looking
                  </OptionButton>
                </ButtonContainer>
              )}

              {step === 2 && (
                <QuestionContainer>
                  <h3>What best describes your business technology need today? <span style={{ fontSize: "1rem" }}>(Check all boxes that apply)</span></h3>
                  <TwoColumn>
                <Column>
                  {leftCategories.map((category, catIndex) => (
                    <div key={catIndex}>
                      <CategoryHeading>{category}</CategoryHeading>
                      {priorityMapping[category].map((item, index) => (
                        <CheckboxLabel key={index}>
                          <CheckboxInput
                            type="checkbox"
                            value={`${category}: ${item.question}`}
                            onChange={handleCheckboxChange}
                          />
                          {item.question}
                        </CheckboxLabel>
                      ))}
                    </div>
                  ))}
                </Column>
                
                <Column>
                  {rightCategories.map((category, catIndex) => (
                    <div key={catIndex}>
                      <CategoryHeading>{category}</CategoryHeading>
                      {priorityMapping[category].map((item, index) => (
                        <CheckboxLabel key={index}>
                          <CheckboxInput
                            type="checkbox"
                            value={`${category}: ${item.question}`}
                            onChange={handleCheckboxChange}
                          />
                          {item.question}
                        </CheckboxLabel>
                      ))}
                    </div>
                  ))}
                </Column>
              </TwoColumn>
              <TranCont>
                  <GoBackButton type="button" onClick={handleGoBack}>Go Back</GoBackButton>
                  <NextButton type="button" onClick={handleNextStep}>Next</NextButton>
                  </TranCont>
                </QuestionContainer>
              )}

              {step === 3 && (
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name">Your Full Name</Label>
                      <Input 
                        id="name" 
                        name="name" 
                        type="text" 
                        placeholder="E.g. John Doe" 
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email">Your Business Email</Label>
                      <Input 
                        id="email" 
                        name="email" 
                        type="email" 
                        placeholder="E.g. john@mail.com" 
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="phone">Your Phone Number</Label>
                      <Input 
                        id="phone" 
                        name="phone" 
                        type="text" 
                        placeholder="E.g. (123) 456-7890" 
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </InputContainer>
                    <SubmitButton type="submit" disabled={loading}>{loading ? "Sending..." : "See Results"}</SubmitButton>
                    <GoBackButton type="button" onClick={handleGoBack}>Go Back</GoBackButton>
                  </Column>
                  <Divider />
                  <Column>
                    <p >Or, you can</p>
                    <LinkButton type="button" onClick={skipToResults}>Skip to Results</LinkButton>
                  </Column>
                </TwoColumn>
              )}

{step === 4 && (
                <TwoColumn>
                  <Column>
                    <h3>Your Recommended Solutions</h3>
                    {selectedSolutions.map((solution, index) => (
                      <ResultItem key={index} href={`/${solution.toLowerCase().replace(" ", "-")}`}>
                        {solution}
                      </ResultItem>
                    ))}
                  </Column>

                  <Column>
                    <h3>Additional Available Solutions</h3>
                    {unselectedSolutions.map((solution, index) => (
                      <ResultItem key={index} href={`/${solution.toLowerCase().replace(" ", "-")}`}>
                        {solution}
                      </ResultItem>
                    ))}
                  </Column>
                </TwoColumn>
              )}
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};

export default QuestionnaireForm;
